<template>
  <div id="app" class="app-container">
    <div class="header">
      <img src="@/assets/logo/Raven.png" alt="Optnova Logo" class="logo" />
      <h1 class="title" style="margin-right: 30px;">Raven</h1>
      <nav class="nav-links">
        <router-link to="/" exact-active-class="active-link">Home</router-link>
          <router-link 
            to="/positions" 
            :class="{ 'active-link': isPositionsActive, 'disabled-link': loading }" 
            :disabled="loading"
          >
            Positions
          </router-link>
          <router-link 
            to="/liquidation" 
            :class="{ 'active-link': isLiquidationActive, 'disabled-link': loading }" 
            :disabled="loading"
          >
            Liquidation
          </router-link>
          <router-link 
            to="/liquidity" 
            :class="{ 'active-link': isLiquidityActive, 'disabled-link': loading }" 
            :disabled="loading"
          >
            Liquidity
          </router-link>
          <!-- <router-link 
            to="/raven-seed-fund" 
            class="new-feature-link"
          >
            $RSF
            <span class="hot-badge">HOT</span>
          </router-link> -->
      </nav>

      <div class="social-icons">
        <a href="https://raven-protocol.gitbook.io/raven-protocol" target="_blank" aria-label="Gitbook">
          <i class="fab fa-github"></i>
        </a>
        <a href="https://x.com/raven_defi" target="_blank" aria-label="Twitter">
          <i class="fab fa-x-twitter"></i>
        </a>
        <!-- <a href="https://discord.gg/your-discord-link" target="_blank" aria-label="Discord">
          <i class="fab fa-discord"></i>
        </a> -->
        <a href="https://t.me/RavenDefi" target="_blank" aria-label="Telegram">
          <i class="fab fa-telegram-plane"></i>
        </a>
        <a href="https://medium.com/@webraven24" target="_blank" aria-label="Medium">
          <i class="fab fa-medium"></i>
        </a>
      </div>

      <div class="wallet-button">
        <w3m-button balance='false' />
      </div>
    </div>
    <router-view></router-view>
    <TransactionBanner ref="transactionBanner" />

    <!-- Acknowledgment Modal -->
    <div v-if="showAcknowledgement" class="overlay">
      <div class="dialog">
        <div class="dialog-content">
          <h2>Acknowledgement</h2>
          <label class="overlay-label">
            <input type="checkbox" v-model="acknowledgeProtocal" />
            I acknowledge that I have read and understood the platform's terms of service, privacy policy, and risk
            disclosure,
            and I understand that Raven is an experimental financial derivative and agree to comply with
            all applicable rules and conditions
          </label>
          <label class="overlay-label">
            <input type="checkbox" v-model="acknowledgeLaws" />
            I confirm that I will not use this platform for any illegal activities, including money laundering,
            terrorist financing, or evading the laws of my country of residence
          </label>
          <label class="overlay-label">
            <input type="checkbox" v-model="acknowledgeCountry" />
            I confirm that I am not located in any country where trading crypto or crypto-related derivatives is legally
            prohibited
          </label>
          <label class="overlay-label">
            <input type="checkbox" v-model="acknowledgeRisk" />
            I understand that the cryptocurrency market is highly volatile, and there is a substantial risk of losing my
            investment
          </label>
          <div class="buttons">
            <button class="buttons-cancel" @click="closeAcknowledgePage">Decline</button>
            <button class="buttons-confirm" @click="confirmAcknowledgement"
              :disabled="!acknowledgeProtocal || !acknowledgeLaws || !acknowledgeCountry || !acknowledgeRisk">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, provide, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/vue'
import { checkVerifiedAddress, verifySignMessage } from './assets/scripts/walletutils'
import { checkAcknowledgedAddress, acknowledge } from './assets/scripts/acknowledge.js'
import TransactionBanner from './components/TransactionBanner.vue'
import './assets/styles/global-style.css'

export default {
  name: 'App',
  components: {
    TransactionBanner,
  },
  computed: {
    isPositionsActive() {
      return this.$route.path.startsWith('/positions')
    },
    isLiquidationActive() {
      return this.$route.path.startsWith('/liquidation')
    },
    isLiquidityActive() {
      return this.$route.path.startsWith('/liquidity')
    }
  },
  setup() {
    const store = useStore()
    const transactionBanner = ref(null)
    const showAcknowledgement = ref(false)
    const acknowledgeProtocal = ref(false)
    const acknowledgeLaws = ref(false)
    const acknowledgeCountry = ref(false)
    const acknowledgeRisk = ref(false)

    let GLOBAL_CHAIN_ID = computed(() => store.getters.GLOBAL_CHAIN_ID)
    let lastConnectedAddress = ref(null)

    const projectId = '3602708d0e242ef88f6644da46a7955a'

    const mainnet = {
      chainId: 1,
      name: 'Ethereum',
      currency: 'ETH',
      explorerUrl: 'https://etherscan.io',
      rpcUrl: 'https://mainnet.infura.io'
    }

    const arbitrum = {
      chainId: 42161,
      name: 'Arbitrum One',
      currency: 'ETH',
      explorerUrl: 'https://arbiscan.io',
      rpcUrl: 'https://arbitrum-mainnet.infura.io'
    }

    const sepolia = {
      chainId: 11155111,
      name: 'Sepolia',
      currency: 'ETH',
      explorerUrl: 'https://sepolia.etherscan.io',
      rpcUrl: 'https://ethereum-sepolia-rpc.publicnode.com'
    }

    const arbitrum_sepolia = {
      chainId: 421614,
      name: 'Arbitrum Sepolia',
      currency: 'ETH',
      explorerUrl: 'https://sepolia.arbiscan.io',
      rpcUrl: 'https://arbitrum-sepolia.blockpi.network/v1/rpc/public'
    }

    const metadata = {
      name: 'My Website',
      description: 'My Website description',
      url: 'https://mywebsite.com',
      icons: ['https://avatars.mywebsite.com/']
    }

    const ethersConfig = defaultConfig({
      metadata,
      enableEIP6963: true,
      enableInjected: true,
      enableCoinbase: true,
      rpcUrl: '...',
      defaultChainId: 1
    })

    let modal = createWeb3Modal({
      ethersConfig,
      chains: [mainnet, arbitrum, sepolia, arbitrum_sepolia],
      projectId,
      enableAnalytics: false
    })

    provide('web3modal', modal)

    let homePageParams = computed(() => store.getters.homePageParams)

    // Define loading state for disabling links
    const loading = ref(true)

    const closeAcknowledgePage = () => {
      alert('Please close this tab to exit.');
    }

    const wrongNetwork = (chainId) => {
      alert('Wrong Network: ' + chainId + ' ! The app may not function properly. \nPlease switch to a supported network Arbitrum One in your wallet settings');
    }

    const confirmAcknowledgement = async () => {
      acknowledgeProtocal.value = false
      acknowledgeLaws.value = false
      acknowledgeCountry.value = false
      acknowledgeRisk.value = false
      showAcknowledgement.value = false
      let currentAddress = modal.getAddress()
      if (!currentAddress) {
        console.log('currentAddress NULL')
      } else {
        await acknowledge(currentAddress)
      }
    }

    const openAcknowledge = async (address) => {
      if (!showAcknowledgement.value) {
        if (!address) {
          showAcknowledgement.value = true
        } else {
          if (! await checkAcknowledgedAddress(address)) {
            showAcknowledgement.value = true
          }
        }
      }
    }

    const handleChange = async ({ provider, address, chainId, isConnected }) => {
      try {
        if (address) { await openAcknowledge(address) }
        if (chainId && GLOBAL_CHAIN_ID.value && chainId != GLOBAL_CHAIN_ID.value) { wrongNetwork(chainId) }
        
        if (isConnected && address !== lastConnectedAddress.value && chainId == GLOBAL_CHAIN_ID.value) {
          lastConnectedAddress.value = address
          const isVerified = await checkVerifiedAddress(address)
          if (!isVerified) {
            let verifyPass = await verifySignMessage(provider)
            if (!verifyPass) {
              return
            }
          }
          if (!homePageParams.value) { await firstLoad() }
          await store.dispatch('fetchRoundPositionLevg', { p_modal: modal, p_curRound: homePageParams.value.RoundNow, p_preRound: homePageParams.value.RoundNow - 1 })
          await store.dispatch('fetchRoundPositionSpot', { p_modal: modal, p_curRound: homePageParams.value.RoundNow, p_preRound: homePageParams.value.RoundNow - 1 })
          await store.dispatch('fetchLiquidityInfo', { p_modal: modal })
        } else if (isConnected && GLOBAL_CHAIN_ID.value && chainId != GLOBAL_CHAIN_ID.value) {
          wrongNetwork(chainId)
        } else {
          await store.dispatch('unfetchRoundPositionSpot')
          await store.dispatch('unfetchRoundPositionLevg')
          await store.dispatch('unfetchLiquidityInfo')
          lastConnectedAddress.value = null
        }
      } catch (error) {
        if (error.message.includes('KeyRing is locked')) {
          console.warn('Keplr is locked, ignoring the error.')
        } else {
          console.log('An unexpected error occurred:', error)
        }
      }
    }

    modal.subscribeProvider(handleChange)

    const firstLoad = async () => {
      try {
        await store.dispatch('fetchHomePageInfo', { p_modal: modal })
        homePageParams = computed(() => store.getters.homePageParams)
        GLOBAL_CHAIN_ID = computed(() => store.getters.GLOBAL_CHAIN_ID)
      } catch (error) {
        console.log('[App.vue] firstLoad error: ', error)
      } finally {
        // Set loading to false after firstLoad completes
        loading.value = false
      }
    }

    onMounted(async () => {
      if (!homePageParams.value) {
        await firstLoad()
      }
    })

    return {
      modal,
      transactionBanner,
      showAcknowledgement,
      acknowledgeProtocal,
      acknowledgeLaws,
      acknowledgeCountry,
      acknowledgeRisk,
      closeAcknowledgePage,
      confirmAcknowledgement,
      loading
    }
  }
}
</script>

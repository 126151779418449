<template>
    <div class="seed-container">
        <div class="raven-plan">
            <!-- Title -->
            <h1 class="main-title">Raven Seed Fund - $RSF</h1>

            <!-- About Raven Protocol -->
            <section class="section">
                <h2>About Raven Protocol</h2>
                <p>
                    Raven Protocol is revolutionizing the option derivatives market by introducing a decentralized platform
                    that combines cutting-edge technology with a community-driven approach. Our mission is simple yet
                    bold:
                    to make financial derivatives accessible, inclusive, and efficient for everyone.
                </p>
                <p>
                    By leveraging blockchain technology and an engaged Web3 community, Raven Protocol introduces a
                    cutting-edge economic model designed to ensure efficiency, robust liquidity, and simplicity. This
                    empowers
                    decentralized finance (DeFi) with innovative solutions to transform the financial derivatives
                    landscape.
                </p>
                <p>
                    More about Raven Protocol: 
                    <a href="https://raven-protocol.gitbook.io/raven-protocol" target="_blank" rel="noopener noreferrer">
                        https://raven-protocol.gitbook.io/raven-protocol
                    </a>
                </p>
            </section>

            <!-- Raven Seed Funding -->
            <section class="section">
                <h2>Raven Seed Funding ($RSF Launch)</h2>
                <p>
                    Unlike traditional seed funding, Raven Protocol embraces the power of the Web3 community to build
                    its foundation.
                    The Raven Seed Funding Token ($RSF) is more than just a funding mechanism—it's a badge of partnership. By
                    holding $RSF,
                    community members actively contribute to building Raven Protocol's foundation, enabling the
                    development of groundbreaking
                    features and fostering a thriving ecosystem.
                </p>
                <p>
                    This token transcends the typical meme coin—it represents the spirit and vision of Raven Protocol,
                    combining the culture
                    of Web3 with the mission of decentralized finance innovation. Proceeds from the seed funding will be
                    strategically allocated
                    to infrastructure upgrades, liquidity provisioning, and innovative feature rollouts.
                </p>
            </section>

            <!-- Why Hold Raven Seed Token -->
            <section class="section">
                <h2>Why Hold Raven Seed Token ($RSF)?</h2>
                <ul>
                    <li><strong>Revenue Sharing:</strong> Earn passive income from a share of the protocol's trading
                        fees.</li>
                    <li><strong>Liquidation Profits:</strong> Receive a portion of liquidation proceeds as Raven
                        Protocol scales.</li>
                    <li><strong>Token Buyback Program:</strong> Benefit from a robust buyback mechanism that stabilizes
                        token value and reduces supply.</li>
                    <li><strong>Future Funding Rewards:</strong> Exclusive participation in future funding rounds,
                        reinforcing your role as a community co-creator.</li>
                    <li><strong>Token Airdrops:</strong> Gain allocations of $Raven governance tokens as a reward for
                        your early involvement.</li>
                    <li><strong>DAO Membership:</strong> Join Raven DAO to shape the protocol's governance and long-term
                        strategy.</li>
                </ul>
            </section>

            <!-- Launch Details -->
            <section class="section">
                <h2>Launch Details</h2>
                <ul>
                    <li><strong>$RSF Launch Platform:</strong> $RSF will launch on <a href="https://pump.fun" target="_blank" rel="noopener noreferrer">pump.fun</a>, on 12-12-2024, 02:00 AM UTC, leveraging its innovative bonding curve mechanism.</li>
                    <li><strong>Developer Allocation:</strong> 178M tokens (17.8%) reserved for the development team.</li>
                    <li><strong>Locked Tokens:</strong> 100M tokens (10%) will be locked until December 2025 to ensure
                        price stability.</li>
                    <li><strong>Seed Round Allocation:</strong> 50M tokens (5%) allocated for community-based seed round
                        funding to support Raven Protocol development.</li>
                    <li><strong>Risk management & Early participant allocation:</strong> 28 million tokens (2.8%) will be allocated to an Emergency Market Safety Vault. If these tokens are not utilized, 
                        they will be redistributed to early participants who actively engage with the protocol through its official social media channels.</li>
                    <li><strong>Buyback Program:</strong> Starting at the end of Q1 2025, Raven Protocol will initiate a
                        token buyback program to ensure long-term market stability and enhance token scarcity.</li>
                </ul>
            </section>

            <!-- Roadmap -->
            <section class="section">
                <h2>Roadmap Milestones</h2>
                <ul>
                    <li><strong>January 2025:</strong> Complete migration to Solana and BNB chains, unlocking higher
                        scalability and faster transaction speeds to support a global user base.</li>
                    <li><strong>February 2025:</strong> Achieve 100 daily spot transactions and $10K liquidity pool
                        deposits. Launch leverage trading, providing users with advanced trading tools.</li>
                    <li><strong>April 2025:</strong> Expand tradable assets, offering diverse opportunities for
                        derivatives traders.</li>
                    <li><strong>June 2025:</strong> Introduce flexible time window positions, catering to different
                        trading preferences and enhancing market efficiency.</li>
                    <li><strong>July 2025:</strong> Officially launch $Raven governance token, empowering holders to
                        participate in protocol decisions.</li>
                    <li><strong>August 2025:</strong> Start distributing continuous rewards to $RSF holders, reinforcing
                        community engagement and loyalty.</li>
                    <li><strong>September 2025:</strong> Initiate Raven DAO, laying the foundation for decentralized
                        governance and a community-driven future.</li>
                </ul>
            </section>

            <!-- Call to Action -->
            <section class="section">
                <h2>Join Us on This Journey</h2>
                <p>
                    Raven Protocol is more than a project—it's a movement toward a decentralized, inclusive, and
                    efficient financial future.
                    By participating in the Raven Seed Funding, you become an integral part of this revolution. Secure
                    your spot as an early builder,
                    and let's redefine the derivatives market together.
                </p>
                <p>
                    Community
                </p>
                <p>
                    X:  
                    <a href="https://x.com/raven_defi" target="_blank" rel="noopener noreferrer">
                        https://x.com/raven_defi
                    </a>
                </p>
                <p>
                    Telegram:  
                    <a href="https://t.me/RavenDefi" target="_blank" rel="noopener noreferrer">
                        https://t.me/RavenDefi
                    </a>
                </p>
                <p>
                    Medium:  
                    <a href="https://medium.com/@webraven24" target="_blank" rel="noopener noreferrer">
                        https://medium.com/@webraven24
                    </a>
                </p>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "RavenSeed",
};
</script>

<style scoped>
.seed-container {
    background-color: #1e1e22;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 68%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 60px;
}

.raven-plan {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    color: #ffffff;
    padding: 20px;
}

.main-title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
    color: rgb(246, 226, 49)
}

.section {
    margin-bottom: 30px;
}

h2 {
    font-size: 1.5rem;
    color: #92f495;
    margin-bottom: 10px;
}

p {
    margin: 10px 0;
}

ul {
    list-style: disc;
    margin-left: 20px;
}

li {
    margin: 5px 0;
}
</style>